<template>
  <v-row justify="center" align="center">
    <div class="col-11 col-sm-6 col-md-4 col-lg-4">
      <v-card
        style="border-radius: 32px"
        class="elevation-1"
      >
        <v-row
          justify="center"
        >
        <div class="text-center col-10">
          <v-img
              src="@/assets/imgs/congratulations.png"
              class="white--text align-center mx-auto mt-4"
              width="170px"
          />
          <span class="primary--text headline mr-0">Congratulations</span>
          <p class="grey--text caption text--darken-1 mr-0">
                  We are glad you made it.
          </p>
            <p class="grey--text subheading text--darken-1 mr-0">
                  Lets get to know you better...
          </p>
        </div>
        <v-card-text class="px-12">
          <v-form @submit.prevent="saveDetails" >
            <v-text-field
              dense
              rounded
              v-model="user.first_name"
              outlined
              prepend-inner-icon="mdi-account"
              label="First Name"
            />
            <v-text-field
              v-model="user.last_name"
              outlined
              rounded
              dense
              prepend-inner-icon="mdi-account"
              label="Last Name"
              required
            />
            <v-text-field
              :disabled="firebase_user.email ? true : false"
              v-model="user.email"
              outlined
              rounded
              dense
              type="email"
              prepend-inner-icon="mdi-at"
              label="Email (Optional)"
            />
            <v-text-field
              :disabled="firebase_user.phoneNumber ? true : false"
              v-model="user.phone"
              outlined
              rounded
              dense
              type="tel"
              prepend-inner-icon="mdi-phone"
              label="Phone"
              required
            />
            <v-btn
              block
              class="elevation-4"
              color="primary"
              rounded
              :loading="loading"
              @click="saveDetails"> CONTINUE
                <v-icon right>
                  mdi-cart-arrow-right
                </v-icon>
            </v-btn>
          </v-form>
          <!-- {{user}} -->
          <!-- {{canSave ? true : false}} -->
          </v-card-text>
        </v-row>
      </v-card>
    </div>
  </v-row>
</template>

<script>
export default {
  name:"AuthLanding",
  data: function () {
    return {
      firebase_user: {},
      user: {},
      loading: false,
    }
  },
  computed:{
    canSave(){
      return
        console.log(
          this.user.first_name &&
          this.user.last_name &&
          this.user.phone
          )
    }
  },
    created(){
      this.firebase.auth().onAuthStateChanged(firebase_user=>{
        if(firebase_user){
          console.log({firebase:firebase_user});
          let [first_name = "", last_name = ""] = firebase_user.displayName ? firebase_user.displayName.split(' ') : "";
          this.user = {
            firebase_id : firebase_user.uid,
            first_name,
            last_name,
            email: firebase_user.email,
            phone: firebase_user.phoneNumber,
            account_type: 'customer',
            enable_notification: 0,
          }
        }else{
          this.$router.push(this.$route.query.history || '/')
        }
      })
    },
  methods: {
    saveDetails(){
      //Checks if names are filled
      if(this.user.first_name && this.user.last_name && this.user.phone){
        //starts loader then creates user
        this.loading = true
        this.$apollo.mutate({
          mutation: require('@/gql/user.gql').create,
          variables: {
            data: this.user
          }
        }).then(res=>{
          // store new user to vuex and push to previous page
          this.$store.commit('setSnack', {
            color: 'green',
            status_msg: 'All done',
            icon: 'mdi-check-circle'
          })
          this.$store.commit('auth/setUserData', res.data.createUser);
          this.$router.push('/sell-with-us');
        }).catch(err=>{
          console.error(err.message)
          this.$store.commit('setSnack', {
            color: 'red',
            status_msg: 'Error proccess your credentials',
            added_msg: 'This may because email or phone already exists',
            icon: 'mdi-information'
          })
        }).finally(_=>{
          this.loading = false
        })
      }else{
        this.$store.commit('setSnack', {
          color: 'orange',
          status_msg: 'Name and phone fields are required',
          icon: 'mdi-information'
        })
      }
    },
}
}
</script>

<style scoped lang="scss">

</style>
